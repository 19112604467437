import React from "react";

import PageLayout from "siteLayouts/Page";

import {
	ContainerMaxWidth,
	Panel,
	PanelSection,
} from "siteComponentsLibrary/Layout";
import { ExternalLink } from "siteComponentsLibrary/Buttons";
import { TelephoneNumber } from "siteComponentsLibrary/Display";

const assets = require("siteAssets/pageManager").default("gap-insurance");
const data = require("siteAssets/datafiles/policyTypesData").default(
	assets.policyType
);

const GapInfo = (props) => {
	const { header, subHeader, info, ipidUrl, policyWordingUrl } = props;

	return (
		<>
			<dt className="col-xl-3 mb-2 mb-xl-4">
				{header}
				{subHeader && <span className="d-block small ps-2">{subHeader}</span>}
			</dt>
			<dd className="col-xl-9 mb-4">
				{info && <div>{info}</div>}
				{ipidUrl && <div>{ipidUrl}</div>}
				{policyWordingUrl && <div>{policyWordingUrl}</div>}
			</dd>
		</>
	);
};

const HistoricPolicyDocs = (props) => {
	const { historic } = props;

	if (!historic) return null;

	const changeDate = require("config").default.claimsContacts.gap.historic.date;
	const claimNumber =
		require("config").default.claimsContacts.gap.historic.number;

	return (
		<div className="alert alert-success mt-3">
			<div className="">
				<a
					data-bs-toggle="collapse"
					data-bs-target="#gaphistoricdocs"
					tabIndex="0"
					href="#gaphistoricdocs"
					role="button"
					aria-expanded="false"
					aria-controls="gaphistoricdocs"
				>
					For policies purchased before <span>{changeDate}</span>, you can find
					your policy documents here.
				</a>
			</div>

			<div className="collapse" id="gaphistoricdocs">
				<div className="pt-3">
					<dl className="row">
						{historic.map((x, i) => {
							const gapData =
								require("siteAssets/datafiles/gapCoverData").default(x);
							return (
								<GapInfo
									key={gapData.id}
									header={gapData.headerText}
									subHeader={gapData.subHeader}
									ipidUrl={
										gapData.ipidUrl && (
											<ExternalLink
												href={gapData.ipidUrl}
												label="Insurance Product Information Document"
												nowrap={false}
											/>
										)
									}
									policyWordingUrl={
										gapData.policyWordingUrl && (
											<ExternalLink
												href={gapData.policyWordingUrl}
												label="Policy Wording"
												nowrap={false}
											/>
										)
									}
								/>
							);
						})}
					</dl>
				</div>

				<div className="mt-3">
					<div>
						To discuss your existing GAP insurance policy, please call us on{" "}
						<TelephoneNumber phonenumber={data.contactNumber} isLink={true} />.
					</div>
					<div>
						If you need to make a claim, please call{" "}
						<TelephoneNumber phonenumber={claimNumber} isLink={true} />.
					</div>
				</div>
			</div>
		</div>
	);
};

const GapInsurance = (props) => {
	return (
		<PageLayout pageTitle={assets.pageTitle} headingText={assets.headingText}>
			<ContainerMaxWidth>
				<Panel header={assets.panelHeader}>
					<PanelSection heading="What is GAP Insurance cover?">
						<div>
							GAP Insurance is designed to offer peace of mind by covering the
							difference between the purchase price or todays value of your
							vehicle and the amount the insurer pays in the unfortunate event
							of a total loss claim.
						</div>
						<div>
							We offer different types of GAP Insurance depending on when and
							how you purchased your vehicle.
						</div>
					</PanelSection>
					<PanelSection>
						<div>
							{(data.gapTypes || data.gapTypesOther) && (
								<dl className="row">
									{data.gapTypes.map((x, i) => {
										const gapData =
											require("siteAssets/datafiles/gapCoverData").default(x);
										return (
											<GapInfo
												key={gapData.id}
												header={gapData.headerText}
												info={gapData.description}
											/>
										);
									})}

									<GapInfo
										info={
											<div className="row">
												<div className="col-12 col-md-6 col-lg-4 col-xl-3">
													<ExternalLink
														className="btn btn-primary w-100"
														href={data.quoteUrl}
														label="Start a quote"
													/>
												</div>
											</div>
										}
									/>

									{data.gapTypesOther.map((x, i) => {
										const gapData =
											require("siteAssets/datafiles/gapCoverData").default(x);
										return (
											<GapInfo
												key={gapData.id}
												header={gapData.headerText}
												info={gapData.description}
											/>
										);
									})}
									{data.gapTypesOther.length >= 1 && (
										<GapInfo
											info={
												<div className="alert alert-success">
													To discuss our GAP Insurance, or request a quote,
													please call us on{" "}
													<TelephoneNumber
														phonenumber={data.contactNumber}
														isLink={true}
													/>
													.
												</div>
											}
										/>
									)}
								</dl>
							)}
						</div>
					</PanelSection>

					<PanelSection heading="Policy Documents">
						<div>
							For further information, please refer to your{" "}
							<ExternalLink
								href={data.ipidUrl}
								label={<span>Insurance Product Information Document</span>}
								nowrap={false}
							/>
							.
						</div>

						<div>
							For full details on what is covered, including the terms,
							conditions and exclusions, please see the{" "}
							<ExternalLink
								href={data.policyWordingUrl}
								label={<span>Policy Wording</span>}
								nowrap={false}
							/>
							.
						</div>

						<HistoricPolicyDocs historic={data.historicGapTypes} />
					</PanelSection>
				</Panel>
			</ContainerMaxWidth>
		</PageLayout>
	);
};

export default GapInsurance;
