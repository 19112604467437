import React from "react";

import { TwoStylePageTitle } from "siteComponentsLibrary/Display";
import { PanelHeader } from "siteComponentsLibrary/Layout";

const MainPanelHeader = (props) => {
	const { leftContent, icon } = props;
	
	return (
		<PanelHeader className="main-header" leftContent={leftContent} icon={icon} />
	)
}

const pageManagerData = {
	/* Site Pages */

	["landing-page"]: {
		pageId: "00",
		pageTitle: "Freedom Brokers",
		pageClass: "landing-page",
	},
	["about-us"]: {
		pageTitle: "About Us",
		//headingText: ( <TwoStylePageTitle icon="icon-people-fill" leftText="About Us" /> ),
		panelHeader: <MainPanelHeader leftContent="About Us" icon="icon-people-fill" />,
	},	
	["contact-us"]: {
		pageTitle: "Contact Us",
		//headingText: ( <TwoStylePageTitle icon="icon-telephone-fill" leftText="Contact Us" /> ),
		panelHeader: <MainPanelHeader leftContent="Contact Us" icon="icon-telephone-fill" />,
	},
	["customer-support"]: {
		pageTitle: "Customer Support",
		//headingText: ( <TwoStylePageTitle icon="icon-helping-hands" leftText="Customer Support" /> ),
		panelHeader: <MainPanelHeader leftContent="Customer Support" icon="icon-helping-hands" />,
	},
	["faqs"]: {
		pageTitle: "FAQs",
		headingText: <TwoStylePageTitle icon="icon-question-lg" leftText="FAQs"/>,
		//panelHeader: <MainPanelHeader leftContent="FAQs" icon="icon-question-lg" />,
	},
	["green-card"]: {
		pageTitle: "Green Card",
		//headingText: ( <TwoStylePageTitle icon="icon-eurostars" leftText="Green Card"/> ),
		panelHeader: <MainPanelHeader leftContent="Green Card" icon="icon-eurostars" />,
	},
	["make-a-claim"]: {
		pageTitle: "Make A Claim",
		//headingText: ( <TwoStylePageTitle icon="icon-clipboard-check" leftText="Make A Claim" /> ),
		panelHeader: <MainPanelHeader leftContent="Make A Claim" icon="icon-clipboard-check" />,
	},
	["make-a-complaint"]: {
		pageTitle: "Make A Complaint",
		//headingText: ( <TwoStylePageTitle icon="icon-complaint" leftText="Make A Complaint" /> ),
		panelHeader: <MainPanelHeader leftContent="Make A Complaint" icon="icon-complaint" />,
	},
	["my-account"]: {
		pageTitle: "Your Online Account",
		//headingText: ( <TwoStylePageTitle icon="icon-laptop" leftText="Your Online Account" /> ),
		panelHeader: <MainPanelHeader leftContent="Your Online Account" icon="icon-laptop" />,
	},
	["work-with-us"]: {
		pageTitle: "Work With Us",
		headingText: ( <TwoStylePageTitle icon="icon-hands" leftText="Work With Us" /> ),
		//panelHeader: <MainPanelHeader leftContent="Work With Us" icon="icon-hands" />,
	},
	["terms-of-business"]: {
		pageTitle: "Terms of Business",
		//headingText: ( <TwoStylePageTitle icon="icon-file-earmark-text" leftText="Terms of Business" /> ),
		panelHeader: <MainPanelHeader leftContent="Terms of Business" icon="icon-file-earmark-text" />,
	},
	["terms-of-use"]: {
		pageTitle: "Terms of Use",
		//headingText: ( <TwoStylePageTitle icon="icon-ui-checks" leftText="Terms of Use" /> ),
		panelHeader: <MainPanelHeader leftContent="Terms of Use" icon="icon-ui-checks" />,
	},
	["privacy-policy"]: {
		pageTitle: "Privacy Policy",
		//headingText: ( <TwoStylePageTitle icon="icon-shield-check" leftText="Privacy Policy" /> ),
		panelHeader: <MainPanelHeader leftContent="Privacy Policy" icon="icon-shield-check" />,
	},
	["cookie-policy"]: {
		pageTitle: "Cookie Policy",
		//headingText: ( <TwoStylePageTitle icon="icon-cookie" leftText="Cookie Policy" /> ),
		panelHeader: <MainPanelHeader leftContent="Cookie Policy" icon="icon-cookie" />,
	},
	["payments"]: {
		pageTitle: "Make a Payment",
		//headingText: ( <TwoStylePageTitle icon="bi bi-currency-pound" leftText="Make a Payment" /> ),
		panelHeader: <MainPanelHeader leftContent="Make a Payment" icon="bi bi-credit-card" />,
		layoutBreakpoint: "xl",
	},

	/* Product Pages */

	["motor-insurance"]: {
		pageTitle: "Motor Insurance",
		policyType: "ALL",
		claimType: "MOTOR",
	},
	
	["telematics"]: {
		pageTitle: "Telematics Insurance",
		pageClass: "telematics-page",

		claimType: "MOTOR",
		
		logoImage: require("siteAssets/images/FB-TODRIVE.png"),
		imageAlt: "Freedom To Drive product logo",
		
		data: {
			howItWorks: [
				{
					stepId: "1",
					imgSrc: require("siteAssets/images/telematics/devices.png"),
					imgAlt: "alt",
					content: "You'll either connect to your car or be sent a device* to install",
					className: "col-12 col-md-4 order-1",
					footnote: "* the device sent will be either the 12v or OBD (on board device), depending on your car.",
					footnoteClassName: "order-1 order-md-2",
				},
				{
					stepId: "2",
					imgSrc: require("siteAssets/images/telematics/connected-car.png"),
					imgAlt: "alt",
					content: "Install the device or connect your car to transmit data to us",
					className: "col-12 col-md-4 order-2 order-md-1",
				},
				{
					stepId: "3",
					imgSrc: require("siteAssets/images/telematics/app-icon.png"),
					imgAlt: "alt",
					content: "Keep track of your driving performance in our app",
					className: "col-12 col-md-4 order-2 order-md-1",
				}
			],
			whatToDo: [
				"install it within 7 days from purchase, following the instruction leaflet",
				"make sure it stays installed",
				"download the smartphone app and stay one of our safer drivers.",
			],
			avoidHighRisk: [
				"sticking to the speed limit",
				"avoiding driving between 11pm-5am",
				"braking smoothly and anticipating corners in advance.",
			],
		},
		
		appLinks: {
			apple: {
				linkUrl: "https://apps.apple.com/au/app/freedom-to-drive/id1669156857",
				appImage: require("./images/applestorebadge.svg"),
			},
			google: {
				linkUrl: "https://play.google.com/store/apps/details?id=uk.co.freedomconnect.freedom_to_drive",
				appImage: require("./images/google-play-badge.png"),
			},
		},		
	},
	["gap-insurance"]: {
		pageTitle: "GAP Insurance",
		//headingText: ( <TwoStylePageTitle icon="icon-policytype-gap" leftText="GAP" rightText="Insurance" /> ),
		panelHeader: <MainPanelHeader leftContent="GAP Insurance" icon="icon-policytype-gap" />,
		
		policyType: "GAP",
		claimType: "GAP",
		callbackProducts: "GAP",
	},

	// #3487
	// ["commercial-insurance"]: {
	// 	pageTitle: "Commercial Insurance",
		
	// 	policyType: "COMMERCIAL",
	// 	claimType: "COMMERCIAL",
	// 	callbackProducts: ["PL", "EL", "TRADE"],
	// },
	
	["home-insurance"]: {
		pageTitle: "Home Insurance",
		headingText: ( <TwoStylePageTitle icon="icon-policytype-home" leftText="Home" rightText="Insurance" /> ),
		headerImages: {},
		policyType: "HC",
		claimType: "HOME",
		image: require("siteAssets/images/views/HomeInsurance/HomelyfeLogo.png"),
		imageAlt: "HomeLyfe logo",

		coverLevels: [
			{
				name: "Contents Only",
				description: (
					<>
						You can buy Contents Insurance on its own and with{" "}
						<span className="money-value">75k</span> cover as standard.
					</>
				),
			},
			{
				name: "Buildings Only",
				description: (
					<>
						Their policies provide up to{" "}
						<span className="money-value">750k</span> worth of cover from the
						moment you buy the policy.
					</>
				),
			},
			{
				name: "Buildings & Contents",
				description: (
					<>
						With cover for <span className="money-value">75k</span> contents and{" "}
						<span className="money-value">750k</span> buildings all in one
						policy.
					</>
				),
			},
		],
	},
	["pet-insurance"]: {
		pageTitle: "Pet Insurance",
		headingText: ( <TwoStylePageTitle icon="icon-policytype-pet" leftText="Pet" rightText="Insurance" /> ),
		headerImages: {},
		policyType: "PET",
		claimType: "PET",
	},

	/* Policy Upgrades */

	["optional-cover"]: {
		pageTitle: "Optional Cover",
		headingText: ( <TwoStylePageTitle icon="icon-bag-plus" leftText="Optional Cover" /> ),
		panelHeader: <MainPanelHeader leftContent="Optional Cover" icon="icon-bag-plus" />,
		opexId: "ALL",
	},
	["breakdown-cover"]: {
		headingText: "UK Breakdown Cover",		
		opexId: "BD",
		usePageTitle: false,
	},
	["excess-protection"]: {
		headingText: "Excess Protection",
		headerImages: {},
		opexId: "XS",
		usePageTitle: false,
	},
	["goods-in-transit"]: {
		headingText: "Goods In Transit",
		headerImages: {},
		opexId: "GOODS",
		usePageTitle: false,
	},
	["key-cover"]: {
		headingText: "Key Cover",
		headerImages: {},
		opexId: "KEY",
		usePageTitle: false,
	},
	["legal-expenses"]: {
		headingText: "Legal Expenses",
		headerImages: {},
		opexId: "LEGAL",
		usePageTitle: false,
	},
	["motor-prosecution-defence"]: {
		headingText: "Motor Prosecution Defence",
		headerImages: {},
		opexId: "LD",
		usePageTitle: false,
	},
	["replacement-hire-vehicle"]: {
		headingText: "Replacement Hire Vehicle",
		headerImages: {},
		opexId: "RPL",
		usePageTitle: false,
	},
	["tools-in-transit"]: {
		headingText: "Tools In Transit",
		headerImages: {},
		opexId: "TOOLS",
		usePageTitle: false,
	},

	["scratch-dent-alloy"]: {
		headingText: "Scratch, Dent & Alloy Protection",
		headerImages: {},
		opexId: "SCRATCH",
		usePageTitle: false,
	},
};

export default pageManagerData;
