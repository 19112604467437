import React from "react";

import PageLayout from "siteLayouts/PageV2";

import {
	ContainerMaxWidth,
	Panel,
	PanelSection,
} from "siteComponentsLibrary/Layout";
import { ListItemsFlex, PortalLinks } from "siteComponentsLibrary/Display";
import { HyperlinkRoute, ExternalLink } from "siteComponentsLibrary/Buttons";

const assets = require("siteAssets/pageManager").default("landing-page");

const portalLogin = require("config").default.links.portal.login;
const portalRegister = require("config").default.links.portal.register;
const quoteStartCar = require("config").default.links.quoteStart.car;
const quoteStartVan = require("config").default.links.quoteStart.van;
const quoteStartTelematics =
	require("config").default.links.quoteStart.telematics;
const quoteStartGap = require("config").default.links.quoteStart.gap;

const LogoSection = (props) => {
	const { imageSrc, imageAlt, text } = props;

	return (
		<PanelSection>
			<div className="mb-3">
				<img src={imageSrc} alt={imageAlt} className="product-logo" />
			</div>
			<div className="product-text">{text}</div>
		</PanelSection>
	);
};

const MotorPanel = (props) => {
	return (
		<Panel
			className="has-border panel-bg"
			classNameBody="pb-0 d-flex flex-column"
			header="Motor Insurance"
			footer={
				<div className="d-flex flex-column flex-sm-row gap-2 justify-content-center">
					<ExternalLink
						href={quoteStartVan}
						label="Get a VAN quote"
						className="btn btn-primary"
						data-cy={`ext-link:van`}
					/>
					<ExternalLink
						href={quoteStartCar}
						label="Get a CAR quote"
						className="btn btn-primary"
						data-cy={`ext-link:car`}
					/>
				</div>
			}
		>
			<LogoSection
				imageSrc={require("siteAssets/images/FB-STANDARD.png")}
				imageAlt="Freedom Standard logo"
				text={
					<span>
						Our great value insurance with a range of optional cover to choose
						from.
					</span>
				}
			/>

			<LogoSection
				imageSrc={require("siteAssets/images/FB-EXTRA.png")}
				imageAlt="Freedom Extra logo"
				text={
					<span>
						Our Extra policy includes Legal Expenses cover at a discounted
						price.
					</span>
				}
			/>

			<LogoSection
				imageSrc={require("siteAssets/images/FB-EXTRAPLUS.png")}
				imageAlt="Freedom Extra Plus logo"
				text={
					<span>
						Extra Plus also includes Legal Expenses cover along with Roadside
						Breakdown, both with great discounts.
					</span>
				}
			/>

			<div className="row flex-fill align-content-end">
				<div className="col-12 text-center">
					<HyperlinkRoute
						route="/motor-insurance"
						label="Find out more"
						title="Find out more"
						className="btn btn-link"
						data-cy={`route-link:motor`}
					/>
				</div>
			</div>
		</Panel>
	);
};

const BlackBoxPanel = (props) => {
	return (
		<Panel
			className="has-border panel-bg"
			classNameBody="pb-0 d-flex flex-column"
			header="'Black box' Car Insurance"
			footer={
				<div className="row justify-content-center">
					<div className="col-12 col-sm-6 col-md-4 col-lg-6">
						<ExternalLink
							href={quoteStartTelematics}
							label="Get a quote"
							className="btn btn-primary w-100"
							data-cy={`ext-link:telematics`}
						/>
					</div>
				</div>
			}
		>
			<LogoSection
				imageSrc={require("siteAssets/images/FB-TODRIVE.png")}
				imageAlt="Freedom To Drive logo"
				text={
					<span>
						Freedom To Drive is our 'black box' telematics car insurance policy.
						It includes an easy self-fitting device that can help to reduce the
						cost of your insurance.
					</span>
				}
			/>

			<div className="row flex-fill align-content-end">
				<div className="col-12 text-center">
					<HyperlinkRoute
						route="/telematics"
						label="Find out more"
						title="Find out more"
						className="btn btn-link"
						data-cy={`route-link:telematics`}
					/>
				</div>
			</div>
		</Panel>
	);
};

const GapPanel = (props) => {
	return (
		<Panel
			className="has-border panel-bg"
			// classNameBody="px-3 pt-3 pb-0 d-flex flex-column"
			classNameBody="pb-0 d-flex flex-column"
			footer={
				<div className="row justify-content-center">
					<div
						// className="col-12 col-sm-6 col-md-12"
						className="col-12 col-sm-6 col-md-4 col-lg-6"
					>
						<ExternalLink
							href={quoteStartGap}
							label="Get a quote"
							className="btn btn-primary w-100"
							data-cy={`ext-link:gap`}
						/>
					</div>
				</div>
			}
		>
			<PanelSection heading="GAP Insurance" className="mb-1">
				{/* <div className="product-text">
					Pays the difference between the purchase price of todays value of your
					vehicle and the amount the insurer pays in the event of a total loss
					claim.
				</div> */}
				<div className="product-text">
					GAP Insurance is designed to offer peace of mind by covering the
					difference between the purchase price or todays value of your vehicle
					and the amount the insurer pays in the unfortunate event of a total
					loss claim.
				</div>
				<div className="product-text">
					We offer different types of GAP Insurance depending on when and how
					you purchased your vehicle.
				</div>
			</PanelSection>

			<div className="row flex-fill align-content-end">
				<div className="col-12 text-center">
					<HyperlinkRoute
						route="/gap-insurance"
						label="Find out more"
						title="Find out more"
						className="btn btn-link"
						data-cy={`route-link:gap`}
					/>
				</div>
			</div>
		</Panel>
	);
};

const CommercialPanel = (props) => {
	return (
		<Panel
			className="has-border panel-bg"
			classNameBody="px-3 pt-3"
			footer={
				<div className="row justify-content-center">
					<div className="col-12 text-center">
						<HyperlinkRoute
							route="/commercial-insurance"
							label="Find out more"
							title="Find out more"
							className="btn btn-link"
							data-cy={`route-link:commercial`}
						/>
					</div>
				</div>
			}
		>
			<PanelSection heading="Tradesman Insurance" className="mb-1">
				<div className="product-text">
					Designed to meet the demands and needs of self-employed individuals
					and small businesses.
				</div>
			</PanelSection>
			<PanelSection heading="Public Liability" className="mb-1">
				<div className="product-text">
					With cover available for the public and employers and starting from as
					little as &pound;70 per year, we can arrange a tailor-made policy for
					you.
				</div>
			</PanelSection>
		</Panel>
	);
};

const LandingView = (props) => {
	return (
		<PageLayout
			showReturnHome={false}
			pageTitle={assets.pageTitle}
			pageClass={assets.pageClass}
			pageId={assets.pageId}
		>
			<div className="container-fluid white-full-width py-4">
				<ContainerMaxWidth>
					<h2 className="mb-3 h3">Our products</h2>
					<div className="row align-items-stretch">
						<div className="col-12 col-lg-5 d-flex align-items-stretch">
							<MotorPanel />
						</div>
						<div className="col-12 col-lg-7">
							<div className="row align-items-stretch gx-3">
								<div className="col-12">
									<BlackBoxPanel />
								</div>
								<div className="col-12 d-flex align-items-stretch">
									<GapPanel />
								</div>
								{/* <div className="col-12 col-md-7 d-flex align-items-stretch">
									<CommercialPanel />
								</div> */}
							</div>
						</div>
					</div>
				</ContainerMaxWidth>
			</div>

			<div className="py-4">
				<ContainerMaxWidth>
					<Panel
						header="Existing Customers"
						className="has-border mt-3 mb-md-4"
					>
						<PanelSection>
							<div className="">
								<strong>
									You can manage your car or van policy online whenever it's
									convenient for you.
								</strong>
							</div>
							<ListItemsFlex
								className="my-3"
								bullet={<span className="bi bi-circle-fill"></span>}
								listData={[
									"View your documents.",
									"Upload your documents, like your proof of no claims bonus.",
									"Make changes to your policy, like change your address, vehicle or add drivers.",
								]}
							/>

							<PortalLinks login={portalLogin} register={portalRegister} />
						</PanelSection>
					</Panel>

					<Panel
						className="has-thick-border mt-3 mb-md-4 py-3"
						classNameBody="py-2"
					>
						<strong>
							We want to make sure you're getting the right support when you
							need it. Visit our{" "}
							<HyperlinkRoute
								route="/customer-support"
								label="Customer Support page"
								className="fw-medium"
								data-cy={`route-link:customer-support`}
							/>{" "}
							to see how we can help.
						</strong>
					</Panel>

					<Panel header="Why choose Freedom Brokers?" className="has-border">
						<PanelSection>
							<ListItemsFlex
								className=""
								bullet={<span className="bi bi-circle-fill"></span>}
								listData={[
									"Straight-forward, uncomplicated insurance with a first class claims service.",
									"Policies tailor-made for you with access to a range of insurers.",
									"Access to your personal online account to make changes to your policy and view your policy documents, 24 hours a day, 365 days a year.",
									"A fantastic range of optional cover, including roadside breakdown, for added peace of mind.",
									"24-hour claims helpline.",
								]}
							/>
						</PanelSection>
					</Panel>
				</ContainerMaxWidth>
			</div>
		</PageLayout>
	);
};

export default LandingView;
