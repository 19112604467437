import React from "react";

import { ExternalLink, DocsBaseUrl, HyperlinkGoto } from "siteComponents";

const policyTypesData = {
	["PC"]: {
		policyType: "car",
		icon: "policytype-car",
		heading: "Car Insurance",
		landingContent: (
			<div>
				Great value insurance for your car. There's also a wide range of policy
				upgrades to choose from.
			</div>
		),
		pageHeading: "Key benefits",
		bullets: [
			"Access to a wide panel of top insurers",
			"Optional Protected No Claims Discount",
			// "Comprehensive Cover",
			"Windscreen Replacement cover included",
			"A great range of policy upgrades",
		],

		showButtons: true,

		moreInfoRoute: "/car-insurance",
		moreInfoClass: "",

		quoteUrl: require("config").default.links.quoteStart.car,
		quoteText: "Start a quote",
		//quoteClass: "", // Used in the PolicyTypeCard
		quoteStartClass: "btn-primary", // Used in the QuoteStartButton

		recallUrl: "https://quote.freedombrokers.co.uk/recall",
		recallText: "Recall a quote",
		recallClass: "",
	},
	["GV"]: {
		policyType: "van",
		icon: "policytype-van",
		heading: "Van Insurance",
		landingContent: (
			<div>
				Great value insurance for your van with comprehensive cover as standard.
				There's also a wide range of policy upgrades to choose from.
			</div>
		),
		pageHeading: "Key benefits",
		bullets: [
			"Access to a panel of insurers",
			"Optional Protected No Claims Discount",
			"Comprehensive Cover",
			"Windscreen Replacement cover",
			"Cover for delivery drivers",
		],

		showButtons: true,

		moreInfoRoute: "/van-insurance",
		moreInfoClass: "",

		quoteUrl: require("config").default.links.quoteStart.van,
		quoteText: "Start a quote",
		//quoteClass: "", // Used in the PolicyTypeCard
		quoteStartClass: "btn-success", // Used in the QuoteStartButton

		recallUrl: "https://quote.freedombrokers.co.uk/recall",
		recallText: "Recall a quote",
		recallClass: "",
	},
	["TELE"]: {
		policyType: "tele",
		icon: "telematics-van-car3",
		moreInfoRoute: "/telematics",

		heading: (
			<>
				<span className="d-block mb-2">
					Telematics Insurance <span className="fw-normal fs-6">with</span>
				</span>
				<span className="">
					<img
						src={require("siteAssets/images/products/TELE-transparent.png")}
						className="title-img img-fluid"
						alt="Freedom To Drive product logo"
					/>
				</span>
			</>
		),
		landingContent: (
			<div>
				Available for car, van & courier drivers. You'll get an upfront discount
				and a smartphone app to monitor your driving performance.
			</div>
		),

		pageHeading: (
			<span className="">
				<img
					src={require("siteAssets/images/products/TELE-transparent.png")}
					className="title-img img-fluid"
					alt="Freedom To Drive product logo"
				/>
			</span>
		),
		bullets: [
			"Telematics 'black box' insurance policy",
			"Available for both Car and Van/Courier drivers",
			"Includes an upfront discount",
			"Smartphone App to see your score",
		],

		showButtons: true,

		policyTypes: [
			{ lookup: "PC", quoteText: "Start a car quote" },
			{ lookup: "GV", quoteText: "Start a van quote" },
		],

		recallUrl: "https://quote.freedombrokers.co.uk/recall",
		recallText: "Recall a quote",
		recallClass: "",
	},
	["HC"]: {
		policyType: "home",
		icon: "policytype-home",
		heading: "Home Insurance",
		landingContent: (
			<div>
				Get an estimate by answering just 4 questions. With contents cover of{" "}
				<span className="money-value">75k</span> and buildings cover of{" "}
				<span className="money-value">750k</span> as standard.
			</div>
		),

		showButtons: true,
		moreInfoRoute: "/home-insurance",
		quoteUrl: "/home-insurance",
		quoteText: "Get a quick quote",
	},
	["PL"]: {
		policyType: "pl",
		icon: "policytype-pl",
		heading: "Liability Insurance",
		landingContent: (
			<div>
				With cover available for the public and employers and starting from as
				little as <span className="money-value">70</span> per year, we can
				arrange a tailor-made policy for you.
			</div>
		),

		contactNumber: "0161 808 9898",
		contactEmail: "publicliability@freedombrokers.co.uk",

		showButtons: true,
		moreInfoRoute: "/public-liability-employers-liability-insurance",

		// #3128
		...(function () {
			if (new Date("1 MAY 2024") > new Date())
				return {
					MoreInfoComponent: ({ children }) => (
						<div className="d-flex justify-content-center">
							<div className="promoted-box p-2 rounded-1">
								<div className="row">
									<div className="col-12">
										<strong>Get £25 off!</strong>
									</div>
									<div className="col-12">
										<HyperlinkGoto route="/public-liability-employers-liability-insurance">
											Click for details
										</HyperlinkGoto>
									</div>
								</div>
							</div>
						</div>
					),
				};

			return {};
		})(),
		quoteUrl: "tel:0161 808 9898",
		quoteText: "Call 0161 808 9898",
	},
	["TRADE"]: {
		policyType: "trade",
		icon: "policytype-trade",
		heading: "Tradesman Insurance",
		landingContent: (
			<div>
				Designed to meet the demands and needs of self-employed individuals and
				small businesses.
			</div>
		),

		contactNumber: "0161 808 9898",
		contactEmail: "publicliability@freedombrokers.co.uk",

		showButtons: true,
		moreInfoRoute: "/tradesman-insurance",
		// #3135
		...(function () {
			if (new Date("1 MAY 2024") > new Date())
				return {
					MoreInfoComponent: ({ children }) => (
						<div className="d-flex justify-content-center">
							<div className="promoted-box p-2 rounded-1">
								<div className="row">
									<div className="col-12">
										<strong>Get £25 off!</strong>
									</div>
									<div className="col-12">
										<HyperlinkGoto route="/tradesman-insurance">
											Click for details
										</HyperlinkGoto>
									</div>
								</div>
							</div>
						</div>
					),
				};

			return {};
		})(),
		quoteUrl: "tel:0161 808 9898",
		quoteText: "Call 0161 808 9898",
	},
	["COURIER"]: {
		heading: "Courier Insurance",
		bullets: ["An item", "A second item", "A third item", "A fourth item"],

		showButtons: true,
		quoteUrl: require("config").default.links.quoteStart.van,
		quoteText: "Start a quote",
		//quoteClass: "",
	},
	["GAP"]: {
		policyType: "gap",
		heading: "GAP Insurance",
		landingContent: (
			<div>
				Pays the difference between the purchase price or todays value of your
				vehicle and the amount the insurer pays in the event of a total loss
				claim.
			</div>
		),
		gapTypes: ["return-to-invoice", "valuation-agreed-value"],
		gapTypesOther: [
			//"contract-hire-lease" //#3487
		],
		historicGapTypes: [
			"old-return-to-invoice",
			"old-contract-hire-lease",
			"old-valuation-agreed-value",
			"old-total-loss-protect-car",
			"old-total-loss-protect-van",
		],

		ipidUrl: DocsBaseUrl + "IPID-GAP-FIGL.pdf",
		policyWordingUrl: DocsBaseUrl + "PW-GAP-FIGL.pdf",

		contactNumber: "0161 808 9899",
		contactEmail: "gap@freedombrokers.co.uk",

		showButtons: true,
		moreInfoRoute: "/gap-insurance",
		// quoteUrl: "tel:01618089899",
		// quoteText: "Call 0161 808 9899",
		quoteUrl: require("config").default.links.quoteStart.gap,
		quoteText: "Start a quote",
	},
	["PET"]: {
		policyType: "pet",
		heading: "Pet Insurance",
		bullets: [
			"Immediate cover available from just 13p a day",
			<span>
				Vet fees for illness and injury up to{" "}
				<span className="money-value">7,500</span>
			</span>,
			"Choose from six cover levels",
		],

		showButtons: true,
		moreInfoRoute: "/pet-insurance",
	},
};

const policyTypesManager = (key) => {
	if (key === "ALL") {
		var dataArray = Array.from(Object.values(policyTypesData));
		return dataArray;
	}

	let foundData = policyTypesData[key];

	if (!foundData) {
		const msg = `policyTypesManager -- can't find ${key}`;
		throw msg;
	}

	return foundData;
};

export default policyTypesManager;
